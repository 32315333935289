import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CloseIcon from '@material-ui/icons/Close';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle
} from '@material-ui/core';
import DOMPurify from 'isomorphic-dompurify';
import Router from 'next/router';

const sanitizer = DOMPurify.sanitize;

class DialogMessage extends Component {
	state = {
		open: this.props.open,
		beneficiariesErrorNew: []
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	render() {
		const {
			fullScreen,
			titular,
			beneficiaryErrors,
			indexBeneficiary,
			payment
		} = this.props;
		const isMobile = Router?.router?.asPath?.includes('/m');
		return (
			<Dialog
				open={this.props.open}
				onClose={this.handleClose}
				disableBackdropClick={true}
				disableEscapeKeyDown={true}
				fullScreen={fullScreen}
				fullWidth={true}
				maxWidth="sm"
			>
				<div
					className="absolute cursor-pointer right-0 p-1 m-2 rounded-full h-6 w-6 bg-gray-300 flex justify-center items-center"
					onClick={() => this.props.handleClose(indexBeneficiary)}
				>
					<CloseIcon className="text-gray-800 text-lg" />
				</div>
				<DialogTitle>
					<span className="text-gray-800 font-bold">{this.props.title}</span>
				</DialogTitle>
				<DialogContent>
					<p className="text-gray-600 my-4">{this.props.message}</p>
					{((this.props.titular &&
						(this.props.titular.include_titular === true ||
							this.props.isAdmin)) ||
						this.props.paymentStep) &&
						this.props.titular?.formErrors && (
							<>
								{this.props.paymentStep &&
									Object.keys(titular.formErrors).length > 0 && (
										<div className="text-gray-600 font-bold mb-4">
											Formulario del titular
										</div>
									)}
								<div className="text-gray-600">
									{Object.keys(titular.formErrors).map((e, i) => (
										<div key={i}>- {titular.formErrors[e]}</div>
									))}
								</div>
							</>
						)}
					{this.props.beneficiaryErrors && (
						<div
							className="text-gray-600 m-0"
							dangerouslySetInnerHTML={{
								__html: sanitizer(
									beneficiaryErrors
										.map((beneficiary, index) => {
											let result =
												beneficiary?.formErrors &&
												Object.keys(beneficiary?.formErrors).length > 0
													? `<p key=${index}>Beneficiario ${index + 1}</p>`
													: '';
											beneficiary?.formErrors &&
												Object.keys(beneficiary?.formErrors).map((b, i) => {
													result += `<div key=${i}>- ${beneficiary?.formErrors[b]}</div>`;
													return true;
												});
											return result;
										})
										.join('')
								)
							}}
						></div>
					)}
					{this.props.payment &&
						Object.keys(payment.formErrors).length > 0 &&
						this.props.paymentStep && (
							<>
								<div className="text-gray-600 font-bold my-4">
									Formulario de pago
								</div>
								<div className="text-gray-600">
									{Object.keys(payment.formErrors).map((e, i) => (
										<div key={i}>- {payment.formErrors[e]}</div>
									))}
								</div>
							</>
						)}
				</DialogContent>
				<DialogActions className={isMobile ? 'mb-10' : ''}>
					<button
						onClick={() => this.props.handleClose(indexBeneficiary)}
						className="white-button-nav"
					>
						Aceptar
					</button>
				</DialogActions>
			</Dialog>
		);
	}
}

DialogMessage.propTypes = {
	fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog({ breakpoint: 'xs' })(DialogMessage);
