import React, { Component } from 'react';

const logo = '/static/images/Asistensi_logotipo.svg';

class LinearProgressBar extends Component {
	render() {
		return (
			<div
				className={
					this.props.showLogo !== false
						? 'splash-screen-content'
						: 'splash-screen-content-small'
				}
			>
				<style global jsx>
					{`
						.splash-screen-content-small {
							width: 100%;
							height: 50px;
							margin-top: 60px;
							margin-bottom: 60px;
							display: flex;
							justify-content: center;
							align-items: center;
							background-color: #fff;
							transition: all 300ms ease;
						}

						.splash-screen-content {
							width: 100%;
							height: calc(100vh - 80px);
							margin-top: 60px;
							display: flex;
							justify-content: center;
							align-items: center;
							background-color: #fff;
							transition: all 300ms ease;
						}

						.splash-screen-content .bounce1,
						.splash-screen-content .bounce2,
						.splash-screen-content .bounce3,
						.splash-screen-content-small .bounce1,
						.splash-screen-content-small .bounce2,
						.splash-screen-content-small .bounce3 {
							width: 15px;
							height: 15px;
							border-radius: 50%;
							margin: 2px;
							background-color: #fb314a;
							-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
							animation: sk-bouncedelay 1.4s infinite ease-in-out both;
						}

						.splash-screen-content .bounce1,
						.splash-screen-content-small .bounce1 {
							-webkit-animation-delay: -0.32s;
							animation-delay: -0.32s;
						}

						.splash-screen-content .bounce2,
						.splash-screen-content-small .bounce2 {
							-webkit-animation-delay: -0.16s;
							animation-delay: -0.16s;
						}
					`}
				</style>
				<div className="flex items-center justify-center flex-col">
					{this.props.showLogo !== false && (
						<img src={logo} alt="logo asistensi" className="w-auto h-16" />
					)}
					<div className="flex items-center justify-center mt-4">
						<div className="bounce1"></div>
						<div className="bounce2"></div>
						<div className="bounce3"></div>
					</div>
				</div>
			</div>
		);
	}
}

export default LinearProgressBar;
