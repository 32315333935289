import React from 'react';
import {
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContent,
	TextField
} from '@material-ui/core';

function ConfirmDialog(props) {
	return (
		<Dialog
			open={props.open}
			onClose={props.handleClose}
			disableBackdropClick={true}
			disableEscapeKeyDown={true}
		>
			<style global jsx>
				{`
					.wInputFull.nm {
						margin: 20px 0 10px !important;
					}
				`}
			</style>
			<DialogTitle>
				<span className="text-gray-800 font-bold">
					{props.title
						? props.title
						: '¿Estas seguro que deseas realizar esta acción?'}
				</span>
			</DialogTitle>
			{props.info && (
				<DialogContent>
					<div className="mb-4 text-base leading-relaxed text-gray-800">
						{props.info}
					</div>
					{props.is_profile === true && (
						<TextField
							multiline
							rows="2"
							name={`motive`}
							label="Motivo"
							type="text"
							helperText={props.formErrors?.motive}
							variant="outlined"
							error={true}
							className="wInputFull nm"
							InputLabelProps={{
								classes: {
									root: !props.motive
										? 'cssLabel'
										: props.formErrors.motive
										? 'cssLabelError'
										: 'cssLabelSuccess'
								},
								shrink: true
							}}
							InputProps={{
								classes: {
									notchedOutline: !props.motive
										? 'cssInput'
										: props.formErrors.motive
										? 'cssInputError'
										: 'cssInputSuccess'
								}
							}}
							margin="normal"
							value={props.motive}
							required={true}
							onChange={(event) => props.handleInput(event)}
						/>
					)}
				</DialogContent>
			)}
			<DialogActions>
				<button
					onClick={props.handleClose}
					className="secondary-button-nav mr-2 min-w-32"
				>
					{props.is_profile === true ? 'Seguir cuidándo' : 'Cancelar'}
				</button>
				{props.cancelSubscription === true ? (
					<button
						onClick={(e) =>
							props.handleApproved(e, ...props.propsConfirm, true)
						}
						className="white-button-nav min-w-32"
					>
						Anular suscripción
					</button>
				) : (
					<button
						onClick={(e) =>
							props.handleApproved(e, ...props.propsConfirm, true)
						}
						className="white-button-nav min-w-32"
					>
						{props.is_profile === true ? 'No renovar' : 'Aceptar'}
					</button>
				)}
			</DialogActions>
		</Dialog>
	);
}

export default ConfirmDialog;
